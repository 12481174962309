<!--
 * @Autor: syq
 * @Date: 2021-07-12 19:23:20
-->
<template>
  <div id="consume">
    <div class="cousume-title" v-for="(item, index) in list" :key="index">
      <van-cell
        class="van-cel"
        center
        :title="item.title"
        :value="item.value"
        :label="item.label"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: "商城消费",
          value: "-10.00",
          label: "2020-01-01 12:00",
        },
        {
          title: "售后退款",
          value: "+10.00",
          label: "2020-01-01 12:00",
        },
        {
          title: "钱包充值",
          value: "+10.00",
          label: "2020-01-01 12:00",
        },
        {
          title: "商城消费",
          value: "-10.00",
          label: "2020-01-01 12:00",
        },
        {
          title: "钱包充值",
          value: "+10.00",
          label: "2020-01-01 12:00",
        },
        {
          title: "商城消费",
          value: "-10.00",
          label: "2020-01-01 12:00",
        },
      ],
    };
  },
};
</script>

<style lang="less">
#consume {
  .cousume-title {
    .van-cell {
      border-bottom: 1px solid #000 !important;
      .van-cell__title {
        font-weight: 600;
      }
      .van-cell__value {
        // font-weight: 600;
        color: red !important;
      }
    }
  }
}
</style>
